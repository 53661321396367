<template>
  <div class="page-container">
    <template>
      <section-title>
        <div class="title-content">
          <img
            class="note-icon"
            src="@/assets/images/register/icon_ts.png"
            alt=""
          />
          <span>注意事项</span>
        </div>
      </section-title>
      <notice
        >1、为了您的手机提供全方面保障服务，当您的手机2、如果你的设备发生意外或想换机时，请根据理赔需求至理赔申请3、提交理赔申请。</notice
      >
    </template>

    <section-title show-asterisk>换机完成照片</section-title>
    <notice class="phone-pic-notice">
      注：请按照下图所示上传换机完成照片。
      <span
        >新手机（按出IMEI码或序列号）+保修卡+入网许可证+销售小票（裸机）+机主身份证</span
      >
    </notice>
    <!--  todo  -->
    <pic-uploader
      @change="(url) => (form.phonePic = url)"
      :src="form.phonePic"
      :example="require('@/assets/images/examplePic/example14.png')"
    ></pic-uploader>

    <section-title show-asterisk>信息填写</section-title>

    <scan-code
      title="新IMEI或序列号"
      placeholder="请输入手机IMEI号或者扫码"
      v-model="form.newIMEI"
    ></scan-code>

    <cell-input
      title="旧IMEI或序列号"
      placeholder="请输入旧手机IMEI号"
      v-model="form.oldIMEI"
    ></cell-input>

    <select-search-cell
      title="新机品牌"
      placeholder="请选择新机品牌"
      v-model="form.newBrandId"
      :options="brandList"
      id-key="brand_id"
      label-key="brand"
      @change="brandChange"
    ></select-search-cell>

    <select-search-cell
      title="新机型号"
      placeholder="请选择新机型号"
      v-model="form.newModelId"
      label-key="model_name"
      id-key="model_id"
      :options="modelList"
      @click.native="modelCellClick"
    ></select-search-cell>

    <field-piker
      label="新机内存"
      title="请选择新机内存大小"
      placeholder="请选择新机内存大小"
      v-model="form.phoneRAM"
      :columns="RAMList"
    ></field-piker>

    <!-- <field-piker
      label="换机方式"
      title="请选择换机方式"
      placeholder="请选择换机方式"
      v-model="form.changeMethod"
      :columns="changeMethodList"
      @getMessage="changeMethodChange"
    ></field-piker> -->
    <van-cell title="换机方式" :value="form.changeMethod || '--'"></van-cell>

    <money
      title="新机裸机价格"
      placeholder="请输入新机裸机价格"
      v-model="form.price"
      color="#333"
      @change="priceChange"
    ></money>
    <!-- @change="priceChange" -->

    <money
      title="补交金额"
      placeholder="请输入补交金额"
      v-model="makeUpPrice"
      disabled
      color="#999"
    ></money>

    <money
      title="理赔金额"
      placeholder="请输入理赔金额"
      v-model="claimPrice"
      disabled
      color="#999"
    ></money>

    <cell-input
      class="order-id"
      placeholder="请输入销单号/订单号"
      v-model="form.orderId"
    >
      <template #title
        >销单号/订单号<span class="optional">(选填)</span></template
      >
    </cell-input>

    <my-button
      class="submit-btn"
      :type="isDone ? 'primary' : 'default'"
      :disabled="!isDone"
      @click="submit"
      >提交
    </my-button>
  </div>
</template>

<script>
import { getPhoneBrand, getPhoneModel } from "@/api";
import { anPrice, claimsOrder, setPlanesAn } from "@/api/changeComplete";
import { isFilled } from "@/utils";

export default {
  name: "ChangeCompleteAndroidApply",
  data() {
    return {
      orderData: {},
      form: {
        phonePic: "",
        newIMEI: "",
        oldIMEI: "",
        newBrandId: "",
        newModelId: "",
        phoneRAM: "",
        price: "",
        changeMethod: "",
        orderId: "",
      },
      isDone: false,

      brandList: [],
      modelList: [],
      RAMList: [],
      changeMethodList: [],

      makeUpPrice: "",
      claimPrice: "",
      finish_id: "",
    };
  },
  watch: {
    form: {
      handler(form) {
        this.checkForm(form);
      },
      deep: true,
    },
  },
  mounted() {
    this.getOrderData();
    // this.getChangeMethodList();
  },
  methods: {
    getOrderData() {
      claimsOrder({ claims_id: this.$route.params.id }).then((res) => {
        let orderData = res.data || {};
        this.form.newIMEI = "";
        this.form.oldIMEI = orderData.imei || "";
        this.form.newBrandId = orderData.brand_id || "";
        this.form.newModelId = orderData.model_id || "";
        this.form.phoneRAM = orderData.ram_size || "";
        this.form.price = orderData.new_price || "";
        this.form.changeMethod = orderData.ins_service_name || "";
        this.makeUpPrice = orderData.money || "";
        this.claimPrice = orderData.claims_money || "";

        this.getBrandList();
        this.getModelList();
        this.orderData = orderData;

        if (this.$route.query.finish_id) {
          console.log(this.$route.query);
          this.finish_id = this.$route.query.finish_id;
          this.form.newIMEI = this.$route.query.imei;
          this.form.phonePic = this.$route.query.tem_img;
          this.form.orderId = this.$route.query.odd_num;
          this.form.changeMethod = this.$route.query.ins_service_name;
          this.makeUpPrice = this.$route.query.money;
          this.form.price = this.$route.query.price;
        }
        this.priceChange();
      });
    },
    checkForm(form) {
      // console.clear()
      let isDone = true;
      Object.keys(form).forEach((key) => {
        if (!isFilled(form[key]) && key !== "orderId") {
          isDone = false;
        }
      });
      this.isDone = isDone;
    },
    getBrandList() {
      getPhoneBrand({ type: "2" }).then((res) => {
        this.brandList = res.data.brandList;
        let ramList = res.data.ramList || [];
        this.RAMList = ramList.map((i) => i.ram_size);
      });
    },
    brandChange() {
      this.modelList = [];
      this.form.newModelId = "";
      this.getModelList();
    },
    modelCellClick() {
      if (!this.form.newBrandId) {
        this.$toast("请先选择手机品牌");
      }
    },
    getModelList() {
      getPhoneModel({ brand_id: this.form.newBrandId }).then((res) => {
        this.modelList = res.data;
      });
    },
    // getChangeMethodList() {
    //   insServiceAn().then((res) => {
    //     this.changeMethodList = res.data;
    //   });
    // },
    priceChange(val) {
      // if (!this.form.changeMethod) {
      //   this.$toast("请先选择换机方式");
      //   this.$nextTick(() => {
      //     this.form.price = "";
      //   });
      //   return;
      // }
      // anPrice({
      //   order_id: this.$route.params.id,
      //   new_price: this.form.price,
      //   ins_service_name: this.form.changeMethod,
      // }).then((res) => {
      //   this.makeUpPrice = res.data.money;
      //   this.claimPrice = res.data.claims_money;
      // });
      this.form.price = val.replace(/[^\d]/g, "");
      this.makeUpPrice = this.form.price - this.claimPrice;
      if (this.makeUpPrice < 0) {
        this.makeUpPrice = "";
      }
      if (this.makeUpPrice < 0) {
        this.$toast("新机裸机价格不能小于理赔金额");
      }
    },
    // changeMethodChange() {
    //   this.priceChange();
    // },
    submit() {
      let { userInfo } = this.$store.state;
      let { form, orderData } = this;
      let params = {
        order_id: orderData.order_id,
        claims_id: orderData.claims_id,
        finish_id: this.$route.query.finish_id || "",
        nickname: userInfo.nickname,
        openid: userInfo.openid,
        headimg: userInfo.headimg,
        tem_img: form.phonePic,
        imei: form.newIMEI,
        old_imei: form.oldIMEI,
        brand_id: form.newBrandId,
        model_id: form.newModelId,
        ram_size: form.phoneRAM,
        new_price: form.price,
        money: this.makeUpPrice,
        claims_money: this.claimPrice,
        ins_service_name: this.form.changeMethod,
        odd_num: form.orderId,
        planes_type: 5,
      };
      setPlanesAn(params)
        .then((res) => {
          if (res && res.code) {
            this.$myDialog({
              type: "success",
              title: "提交成功",
              confirmButtonText: "查看详情",
              beforeClose: (action, done) => {
                if (action === "confirm") {
                  done();
                  this.$router.push(
                    "/ChangeComplete/detail/" + res.data.finish_id
                  );
                } else {
                  done();
                }
              },
            });
          } else {
            this.$toast(res.msg || "提交失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "提交失败");
        });
    },
  },
};
</script>

<style scoped lang="less">
.title-content {
  display: flex;
  align-items: center;

  .note-icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}

.phone-pic-notice {
  margin-bottom: 30px;

  span {
    color: #e86161;
  }
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}

::v-deep .order-id .van-cell__title {
  min-width: 10em;

  .optional {
    color: #999999;
    font-size: 24px;
    position: relative;
    top: 2px;
  }
}
</style>
