<template>
  <div :is="type"></div>
</template>

<script>
import android from "@/views/ChangeComplete/apply/android";
import iphone from "@/views/ChangeComplete/apply/iphone";
import androidYearChange from "@/views/ChangeComplete/apply/androidYearChange";
import iphoneYearChange from "@/views/ChangeComplete/apply/iphoneYearChange";
import zhixuanhuanji from "@/views/ChangeComplete/apply/zhixuanhuanji";
import upServer from "@/views/ChangeComplete/apply/upServer";
export default {
  name: "index",
  props: {
    type: String,
    id: String,
  },
  components: {
    android,
    iphone,
    androidYearChange,
    iphoneYearChange,
    zhixuanhuanji,
    upServer
  },
};
</script>

<style scoped>
</style>
